// package: kevbox.auth.v1
// file: kevbox/auth/v1/users_api.proto

var kevbox_auth_v1_users_api_pb = require("../../../kevbox/auth/v1/users_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var UsersAPI = (function () {
  function UsersAPI() {}
  UsersAPI.serviceName = "kevbox.auth.v1.UsersAPI";
  return UsersAPI;
}());

UsersAPI.CreateUser = {
  methodName: "CreateUser",
  service: UsersAPI,
  requestStream: false,
  responseStream: false,
  requestType: kevbox_auth_v1_users_api_pb.CreateUserRequest,
  responseType: kevbox_auth_v1_users_api_pb.CreateUserResponse
};

UsersAPI.ListUsers = {
  methodName: "ListUsers",
  service: UsersAPI,
  requestStream: false,
  responseStream: false,
  requestType: kevbox_auth_v1_users_api_pb.ListUsersRequest,
  responseType: kevbox_auth_v1_users_api_pb.ListUsersResponse
};

UsersAPI.WhoAmI = {
  methodName: "WhoAmI",
  service: UsersAPI,
  requestStream: false,
  responseStream: false,
  requestType: kevbox_auth_v1_users_api_pb.WhoAmIRequest,
  responseType: kevbox_auth_v1_users_api_pb.WhoAmIResponse
};

exports.UsersAPI = UsersAPI;

function UsersAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

UsersAPIClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersAPI.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersAPIClient.prototype.listUsers = function listUsers(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersAPI.ListUsers, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

UsersAPIClient.prototype.whoAmI = function whoAmI(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(UsersAPI.WhoAmI, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.UsersAPIClient = UsersAPIClient;

