import { WhoAmIRequest, WhoAmIResponse } from "./kevbox/auth/v1/users_api_pb";
import { UsersAPIClient } from "./kevbox/auth/v1/users_api_pb_service";
import {
  CheckSystemHealthRequest,
  CheckSystemHealthResponse,
} from "./kevbox/telemetry/v1/telemetry_api_pb";
import { grpc } from "@improbable-eng/grpc-web";
import { TelemetryAPIClient } from "./kevbox/telemetry/v1/telemetry_api_pb_service";

console.log(process.env.BASE_URL);

let host = window.location.host;
if (process.env.BASE_URL) {
  host = process.env.BASE_URL;
} else if (
  window.location.hostname.includes("127.0.0.1") ||
  window.location.hostname == ""
)
  host = "localhost";
const authClient = new UsersAPIClient(`https://${host}`);
const telemetryClient = new TelemetryAPIClient(`https://${host}`);

export async function whoAmI(): Promise<WhoAmIResponse> {
  const request = new WhoAmIRequest();
  return new Promise<WhoAmIResponse>((resolve) => {
    authClient.whoAmI(request, new grpc.Metadata(), (err, response) => {
      if (err) {
        console.log(
          `Unexpected error for WhoAmI: code = ${err.code}` +
            `, message = "${err.message}"`,
        );
        return;
      } else if (!response) {
        console.log(`Unexpected null response from WhoAmI"`);
        return;
      }
      console.log(response);
      resolve(response);
    });
  });
}

export async function checkSystemHealth(): Promise<CheckSystemHealthResponse> {
  const request = new CheckSystemHealthRequest();
  return new Promise<CheckSystemHealthResponse>((resolve) => {
    telemetryClient.checkSystemHealth(
      request,
      new grpc.Metadata(),
      (err, response) => {
        if (err) {
          console.log(
            `Unexpected error for CheckSystemHealth: code = ${err.code}` +
              `, message = "${err.message}"`,
          );
          return;
        } else if (!response) {
          console.log(`Unexpected null response from CheckSystemHealth"`);
          return;
        }
        console.log(response);
        resolve(response);
      },
    );
  });
}
