import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import "./App.css";
import { checkSystemHealth } from "./api";
import {
  CheckSystemHealthResponse,
  ServiceHealth,
} from "./kevbox/telemetry/v1/telemetry_api_pb";
import {
  HealthCheckResult,
  HealthStatus,
  HealthStatusMap,
} from "./kevbox/telemetry/v1/health_api_pb";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

function Health() {
  const [report, setReport] = useState<CheckSystemHealthResponse | null>(null);

  const checkHealth = useCallback(async () => {
    const resp = await checkSystemHealth();
    if (resp) {
      setReport(resp);
    }
  }, []);

  useEffect(() => {
    checkHealth().catch(console.error);
  }, [checkHealth]);

  let healthStatusesList = report?.getHealthStatusesList();

  healthStatusesList = healthStatusesList?.sort();
  return (
    <List>
      <List>
        {renderStatus(report?.getSystemStatus())}
        <Typography variant="h3">Kevbox Health</Typography>
      </List>
      <Grid container sx={{ width: "100%", maxWidth: 750, bgcolor: "black" }}>
        {healthStatusesList?.map((serviceHealth) => (
          <Service serviceHealth={serviceHealth} />
        ))}
      </Grid>
    </List>
  );
}

export default Health;

function Service({ serviceHealth }: { serviceHealth: ServiceHealth }) {
  const isPass =
    serviceHealth?.getServiceStatus() == HealthStatus.HEALTH_STATUS_PASS;
  const [open, setOpen] = useState(!isPass);

  const handleClick = () => {
    setOpen(!open);
  };

  const results = serviceHealth.getCheckResultsList().sort();
  const numFails = results.reduce(
    (totalFails, result) =>
      totalFails +
      (result.getCheckStatus() != HealthStatus.HEALTH_STATUS_PASS ? 1 : 0),
    0,
  );

  return (
    <Box>
      <Button onClick={handleClick}>
        <List>
          <Badge badgeContent={numFails} color="error">
            {renderStatus(serviceHealth?.getServiceStatus())}
          </Badge>
          <ListItemText primary={serviceHealth.getServiceName()}></ListItemText>
        </List>
      </Button>
      <Collapse in={open} timeout="auto">
        <Grid sx={{ bgcolor: "gray" }}>
          {results.map((checkResult) => (
            <Check checkResult={checkResult} />
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
}

function Check({ checkResult }: { checkResult: HealthCheckResult }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <List>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ListItemIcon>
          <ListItemAvatar>
            {renderStatus(checkResult?.getCheckStatus())}
          </ListItemAvatar>
        </ListItemIcon>
        <ListItemText primary={checkResult?.getExternalName()} />
      </Box>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ListItemText
          primary={<i>{checkResult?.getExternalMessage()}</i>}
          primaryTypographyProps={{
            style: { color: "#888888", fontSize: "12px" },
          }}
          sx={{ padding: 1 }}
        />
      </Popover>
    </List>
  );
}

const renderStatus = (status?: HealthStatusMap[keyof HealthStatusMap]) => {
  switch (status) {
    case HealthStatus.HEALTH_STATUS_PASS:
      return <CheckCircle color={"success"} />;
    case HealthStatus.HEALTH_STATUS_FAIL:
      return <Cancel color={"error"} />;
    default:
      return null;
  }
};
