import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { pink } from "@mui/material/colors";
import logo from "./logo.svg";
import "./App.css";
import { whoAmI } from "./api";
import { User } from "./kevbox/auth/v1/users_pb";
import { Box } from "@mui/material";
import Health from "./health";

// https://create-react-app.dev/docs/adding-typescript/
// https://mui.com/material-ui/getting-started/usage/

function App() {
  const [user, setUser] = useState<User | null>(null);

  // https://devtrium.com/posts/async-functions-useeffect
  const updateUser = useCallback(async () => {
    const resp = await whoAmI();
    const user = resp.getUser();
    if (user) {
      // Update `User` state on WhoAmI call
      setUser(user);
    }
  }, []);

  useEffect(() => {
    updateUser().catch(console.error);
  }, [updateUser]);

  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Set the expiry to the past
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  const onLogin = () => {
    window.location.href = "/authx/login";
    updateUser();
  };

  const onLogout = () => {
    clearAllCookies();
    window.location.href = "/authx/logout";
    setUser(null); // Clear the `User` state
  };

  return (
    <div className="App">
      <div className="App-body">
        {user ? (
          <>
            <Health />
            {/*TODO(kevinsu): Move health dashboard under its own route*/}
            <Typography variant="h5">
              {/*TODO(kevinsu: Move username to top bar*/}
              {user?.getUsername()}
            </Typography>
            <Box m={1} pt={1}>
              <Button id="logout-btn" variant="contained" onClick={onLogout}>
                Logout
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Avatar
              component={Link}
              to="https://github.com/ksu22"
              sx={{ height: "40vmin", width: "40vmin" }}
            >
              <Avatar
                className="App-logo"
                src={logo}
                sx={{ bgcolor: pink[500], height: "40vmin", width: "40vmin" }}
              />
            </Avatar>
            <Box m={1} pt={1}>
              <Button id="login-btn" variant="contained" onClick={onLogin}>
                Login
              </Button>
            </Box>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
