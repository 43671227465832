// source: kevbox/auth/v1/users_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var kevbox_auth_v1_users_pb = require('../../../kevbox/auth/v1/users_pb.js');
goog.object.extend(proto, kevbox_auth_v1_users_pb);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserRequest', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserRequest.Internal', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserRequest.UserCase', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse.Failure', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse.Success', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse.Success.Internal', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.CreateUserResponse.Success.UserCase', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.ListUsersRequest', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.ListUsersResponse', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.WhoAmIRequest', null, global);
goog.exportSymbol('proto.kevbox.auth.v1.WhoAmIResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.kevbox.auth.v1.CreateUserRequest.oneofGroups_);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserRequest.displayName = 'proto.kevbox.auth.v1.CreateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserRequest.Internal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserRequest.Internal.displayName = 'proto.kevbox.auth.v1.CreateUserRequest.Internal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserResponse.displayName = 'proto.kevbox.auth.v1.CreateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserResponse.Success = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.kevbox.auth.v1.CreateUserResponse.Success.oneofGroups_);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserResponse.Success, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserResponse.Success.displayName = 'proto.kevbox.auth.v1.CreateUserResponse.Success';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserResponse.Success.Internal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.displayName = 'proto.kevbox.auth.v1.CreateUserResponse.Success.Internal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.CreateUserResponse.Failure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.CreateUserResponse.Failure.displayName = 'proto.kevbox.auth.v1.CreateUserResponse.Failure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.ListUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.ListUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.ListUsersRequest.displayName = 'proto.kevbox.auth.v1.ListUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.ListUsersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kevbox.auth.v1.ListUsersResponse.repeatedFields_, null);
};
goog.inherits(proto.kevbox.auth.v1.ListUsersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.ListUsersResponse.displayName = 'proto.kevbox.auth.v1.ListUsersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.WhoAmIRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.WhoAmIRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.WhoAmIRequest.displayName = 'proto.kevbox.auth.v1.WhoAmIRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kevbox.auth.v1.WhoAmIResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kevbox.auth.v1.WhoAmIResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kevbox.auth.v1.WhoAmIResponse.displayName = 'proto.kevbox.auth.v1.WhoAmIResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.kevbox.auth.v1.CreateUserRequest.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.kevbox.auth.v1.CreateUserRequest.UserCase = {
  USER_NOT_SET: 0,
  INTERNAL: 1
};

/**
 * @return {proto.kevbox.auth.v1.CreateUserRequest.UserCase}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.getUserCase = function() {
  return /** @type {proto.kevbox.auth.v1.CreateUserRequest.UserCase} */(jspb.Message.computeOneofCase(this, proto.kevbox.auth.v1.CreateUserRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    internal: (f = msg.getInternal()) && proto.kevbox.auth.v1.CreateUserRequest.Internal.toObject(includeInstance, f),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserRequest}
 */
proto.kevbox.auth.v1.CreateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserRequest;
  return proto.kevbox.auth.v1.CreateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserRequest}
 */
proto.kevbox.auth.v1.CreateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kevbox.auth.v1.CreateUserRequest.Internal;
      reader.readMessage(value,proto.kevbox.auth.v1.CreateUserRequest.Internal.deserializeBinaryFromReader);
      msg.setInternal(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kevbox.auth.v1.CreateUserRequest.Internal.serializeBinaryToWriter
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserRequest.Internal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserRequest.Internal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserRequest.Internal}
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserRequest.Internal;
  return proto.kevbox.auth.v1.CreateUserRequest.Internal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserRequest.Internal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserRequest.Internal}
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserRequest.Internal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserRequest.Internal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserRequest.Internal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional Internal internal = 1;
 * @return {?proto.kevbox.auth.v1.CreateUserRequest.Internal}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.getInternal = function() {
  return /** @type{?proto.kevbox.auth.v1.CreateUserRequest.Internal} */ (
    jspb.Message.getWrapperField(this, proto.kevbox.auth.v1.CreateUserRequest.Internal, 1));
};


/**
 * @param {?proto.kevbox.auth.v1.CreateUserRequest.Internal|undefined} value
 * @return {!proto.kevbox.auth.v1.CreateUserRequest} returns this
*/
proto.kevbox.auth.v1.CreateUserRequest.prototype.setInternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.kevbox.auth.v1.CreateUserRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kevbox.auth.v1.CreateUserRequest} returns this
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.clearInternal = function() {
  return this.setInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.hasInternal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kevbox.auth.v1.CreateUserRequest} returns this
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kevbox.auth.v1.CreateUserRequest} returns this
 */
proto.kevbox.auth.v1.CreateUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse}
 */
proto.kevbox.auth.v1.CreateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserResponse;
  return proto.kevbox.auth.v1.CreateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse}
 */
proto.kevbox.auth.v1.CreateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.UserCase = {
  USER_NOT_SET: 0,
  INTERNAL: 1
};

/**
 * @return {proto.kevbox.auth.v1.CreateUserResponse.Success.UserCase}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.getUserCase = function() {
  return /** @type {proto.kevbox.auth.v1.CreateUserResponse.Success.UserCase} */(jspb.Message.computeOneofCase(this, proto.kevbox.auth.v1.CreateUserResponse.Success.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserResponse.Success.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.toObject = function(includeInstance, msg) {
  var f, obj = {
    internal: (f = msg.getInternal()) && proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserResponse.Success;
  return proto.kevbox.auth.v1.CreateUserResponse.Success.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kevbox.auth.v1.CreateUserResponse.Success.Internal;
      reader.readMessage(value,proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.deserializeBinaryFromReader);
      msg.setInternal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserResponse.Success.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && kevbox_auth_v1_users_pb.User.toObject(includeInstance, f),
    internalUserCreds: (f = msg.getInternalUserCreds()) && kevbox_auth_v1_users_pb.InternalUserCreds.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserResponse.Success.Internal;
  return proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new kevbox_auth_v1_users_pb.User;
      reader.readMessage(value,kevbox_auth_v1_users_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new kevbox_auth_v1_users_pb.InternalUserCreds;
      reader.readMessage(value,kevbox_auth_v1_users_pb.InternalUserCreds.deserializeBinaryFromReader);
      msg.setInternalUserCreds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      kevbox_auth_v1_users_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getInternalUserCreds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      kevbox_auth_v1_users_pb.InternalUserCreds.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.kevbox.auth.v1.User}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.getUser = function() {
  return /** @type{?proto.kevbox.auth.v1.User} */ (
    jspb.Message.getWrapperField(this, kevbox_auth_v1_users_pb.User, 1));
};


/**
 * @param {?proto.kevbox.auth.v1.User|undefined} value
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} returns this
*/
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} returns this
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InternalUserCreds internal_user_creds = 2;
 * @return {?proto.kevbox.auth.v1.InternalUserCreds}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.getInternalUserCreds = function() {
  return /** @type{?proto.kevbox.auth.v1.InternalUserCreds} */ (
    jspb.Message.getWrapperField(this, kevbox_auth_v1_users_pb.InternalUserCreds, 2));
};


/**
 * @param {?proto.kevbox.auth.v1.InternalUserCreds|undefined} value
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} returns this
*/
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.setInternalUserCreds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} returns this
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.clearInternalUserCreds = function() {
  return this.setInternalUserCreds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.Internal.prototype.hasInternalUserCreds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Internal internal = 1;
 * @return {?proto.kevbox.auth.v1.CreateUserResponse.Success.Internal}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.getInternal = function() {
  return /** @type{?proto.kevbox.auth.v1.CreateUserResponse.Success.Internal} */ (
    jspb.Message.getWrapperField(this, proto.kevbox.auth.v1.CreateUserResponse.Success.Internal, 1));
};


/**
 * @param {?proto.kevbox.auth.v1.CreateUserResponse.Success.Internal|undefined} value
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success} returns this
*/
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.setInternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.kevbox.auth.v1.CreateUserResponse.Success.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Success} returns this
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.clearInternal = function() {
  return this.setInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kevbox.auth.v1.CreateUserResponse.Success.prototype.hasInternal = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.CreateUserResponse.Failure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Failure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Failure}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.CreateUserResponse.Failure;
  return proto.kevbox.auth.v1.CreateUserResponse.Failure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Failure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Failure}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.CreateUserResponse.Failure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Failure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason = {
  REASON_UNSPECIFIED: 0,
  REASON_INVALID_EMAIL: 1,
  REASON_EMAIL_ALREADY_EXISTS: 2,
  REASON_INVALID_USERNAME: 3,
  REASON_USERNAME_ALREADY_EXISTS: 4
};

/**
 * optional Reason reason = 1;
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.getReason = function() {
  return /** @type {!proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.kevbox.auth.v1.CreateUserResponse.Failure.Reason} value
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Failure} returns this
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.setReason = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kevbox.auth.v1.CreateUserResponse.Failure} returns this
 */
proto.kevbox.auth.v1.CreateUserResponse.Failure.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.ListUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.ListUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.ListUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.ListUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.ListUsersRequest}
 */
proto.kevbox.auth.v1.ListUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.ListUsersRequest;
  return proto.kevbox.auth.v1.ListUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.ListUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.ListUsersRequest}
 */
proto.kevbox.auth.v1.ListUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.ListUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.ListUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.ListUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.ListUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kevbox.auth.v1.ListUsersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.ListUsersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.ListUsersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.ListUsersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.ListUsersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    kevbox_auth_v1_users_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.ListUsersResponse}
 */
proto.kevbox.auth.v1.ListUsersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.ListUsersResponse;
  return proto.kevbox.auth.v1.ListUsersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.ListUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.ListUsersResponse}
 */
proto.kevbox.auth.v1.ListUsersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new kevbox_auth_v1_users_pb.User;
      reader.readMessage(value,kevbox_auth_v1_users_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.ListUsersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.ListUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.ListUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.ListUsersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      kevbox_auth_v1_users_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * repeated User users = 1;
 * @return {!Array<!proto.kevbox.auth.v1.User>}
 */
proto.kevbox.auth.v1.ListUsersResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.kevbox.auth.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, kevbox_auth_v1_users_pb.User, 1));
};


/**
 * @param {!Array<!proto.kevbox.auth.v1.User>} value
 * @return {!proto.kevbox.auth.v1.ListUsersResponse} returns this
*/
proto.kevbox.auth.v1.ListUsersResponse.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.kevbox.auth.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kevbox.auth.v1.User}
 */
proto.kevbox.auth.v1.ListUsersResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.kevbox.auth.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kevbox.auth.v1.ListUsersResponse} returns this
 */
proto.kevbox.auth.v1.ListUsersResponse.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.WhoAmIRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.WhoAmIRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.WhoAmIRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.WhoAmIRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.WhoAmIRequest}
 */
proto.kevbox.auth.v1.WhoAmIRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.WhoAmIRequest;
  return proto.kevbox.auth.v1.WhoAmIRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.WhoAmIRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.WhoAmIRequest}
 */
proto.kevbox.auth.v1.WhoAmIRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.WhoAmIRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.WhoAmIRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.WhoAmIRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.WhoAmIRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kevbox.auth.v1.WhoAmIResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kevbox.auth.v1.WhoAmIResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kevbox.auth.v1.WhoAmIResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.WhoAmIResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && kevbox_auth_v1_users_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kevbox.auth.v1.WhoAmIResponse}
 */
proto.kevbox.auth.v1.WhoAmIResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kevbox.auth.v1.WhoAmIResponse;
  return proto.kevbox.auth.v1.WhoAmIResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kevbox.auth.v1.WhoAmIResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kevbox.auth.v1.WhoAmIResponse}
 */
proto.kevbox.auth.v1.WhoAmIResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new kevbox_auth_v1_users_pb.User;
      reader.readMessage(value,kevbox_auth_v1_users_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kevbox.auth.v1.WhoAmIResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kevbox.auth.v1.WhoAmIResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kevbox.auth.v1.WhoAmIResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kevbox.auth.v1.WhoAmIResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      kevbox_auth_v1_users_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.kevbox.auth.v1.User}
 */
proto.kevbox.auth.v1.WhoAmIResponse.prototype.getUser = function() {
  return /** @type{?proto.kevbox.auth.v1.User} */ (
    jspb.Message.getWrapperField(this, kevbox_auth_v1_users_pb.User, 1));
};


/**
 * @param {?proto.kevbox.auth.v1.User|undefined} value
 * @return {!proto.kevbox.auth.v1.WhoAmIResponse} returns this
*/
proto.kevbox.auth.v1.WhoAmIResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kevbox.auth.v1.WhoAmIResponse} returns this
 */
proto.kevbox.auth.v1.WhoAmIResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kevbox.auth.v1.WhoAmIResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.kevbox.auth.v1);
